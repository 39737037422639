.topbar{
    height: 100px;
    position: sticky;
    top:0;
    z-index: 999;
    overflow: auto;
    margin: auto;
    background-color: #FCF4F4;
}
.topbarWrapper{
    height: 100%;
    padding: 0 40px;
    display: flex;
}
.topRight{
    display: flex;
    align-items: center;
}
.map{
    font-family: 'Inconsolata', monospace;
    font-size: 18px;
    cursor: pointer;
}
.dot {
    height: 5px;
    width: 5px;
    background-color: #1d225e;
    border-radius: 50%;
    display: inline-block;
}
.nav-colored { background-color:#000; }
.nav-transparent { background-color:transparent;}