.header {
    font-family: 'ADLaM Display', cursive;
}

.question {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-size: 20px;
}

.paragraph {
    font-family: 'Work Sans', sans-serif;
}

.contactDetails {
    z-index: 999;
    padding: 20px;
    background-color: #F2F3F8;
    -webkit-box-shadow: 10px 10px 15px -10px rgba(0, 0, 0, 0.9);
    box-shadow: 10px 10px 15px -10px rgba(0, 0, 0, 0.9);;
}

.getInTouch {
    margin-left: -50px;
    border: 2px solid #bbbbbb;
    border-radius: 5px;
    width: 100%;
}

span {
    margin-left: 10px;
    font-family: 'Work Sans', sans-serif;
}


@media only screen and (max-width: 700px) {
    .home {
        width: calc(100vw - 80px);
    }

    .homeWidgets {
        display: none;
    }
}